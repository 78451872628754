import React, { Component } from 'react';
import Nav from "../../components/NavigationBlog/NavigationBlog"
import ProgressBar from "../../elements/ProgressBar/ProgressBar"
import Divider from "../../elements/Divider/Divider"
import GridOne from "../../components/GridOne/GridOne"
import {BodyWrapper,PostContentWrapper,EmptyPageHeading} from "../../css/style"
class category extends Component {
    constructor (props){
        super(props);
        this.state = {
            scrollPosition: 0,
        }
    }
    listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            this.calculateScrollDistance();
          });
        });
      }
    
      calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = this.getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
        this.setState({
          scrollPostion,
        });
      }
    
      getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
      }
    //   getMonth = () =>{
    //     const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    //     var date = new Date(this.props.data.date); 
    //     return (mlist[date.getMonth()+1])
    //   }
      componentDidMount() {
        this.listenToScrollEvent();
      }
    render() {
        return (
            <BodyWrapper>
            <Nav></Nav>
            <ProgressBar scroll={this.state.scrollPostion + '%'} active={false} />
            <PostContentWrapper>
                {/* <ArchiveHeading/> */}
                <EmptyPageHeading />
                <Divider CategoryHeading={this.props.categoryName}></Divider>
                <GridOne data={this.props.data.allWpPost.nodes}></GridOne>
            </PostContentWrapper>
            </BodyWrapper>
        );
    }
}
export default category;