import React, { Component } from 'react';
import {Link} from "gatsby";
import './css/gridContentBlock.css'
import GridPostCategories from '../../elements/GridPostCategories/GridPostCategories'
import {CatalogueMainText} from "../../css/style"
class GridImage extends Component {
    constructor (props){
        super(props);
        this.state = {
            blur: false,
            readMore:false,
        }
        this.ImageBlur = this.ImageBlur.bind(this);
        this.ImageBlurDeactivate = this.ImageBlurDeactivate.bind(this);
        this.readMoreAnimate = this.readMoreAnimate.bind(this);
        this.ImageBlurMobile = this.ImageBlurMobile.bind(this);        
    }
    ImageBlurMobile()
    {
        this.setState({
            blur:!this.state.blur,
        })
    }
    ImageBlur()
    {
        this.setState({
            blur:true,
        })
    }
    ImageBlurDeactivate()
    {
        this.setState({
            blur:false,
        })
    }
    readMoreAnimate()
    {
        this.setState({
            readMore:!this.state.readMore,
        })
    }
    componentDidMount(){
        this.setState({
            blur:this.props.hoverState,
        })
    }
    render() {
        return (
            <>
            {this.props.data.featuredImage?
                <>
                <div className={this.state.blur? 'grid-image blur':'grid-image'} style={{"background-image":`url(${this.props.data.featuredImage.node.mediaItemUrl})`}}></div>
                {/* <div className={this.state.blur? 'grid-image-blur hover':'grid-image-blur'} style={{"background-image":`url(${this.props.data.featuredImage.node.mediaItemUrl})`}}></div> */}
                </>
                :
                <>
                <div className="grid-image" style={{"background-image":"url('../../../images/rural-electrification-scaled.jpg')"}}></div>
                {/* <div className={this.state.blur? 'grid-image-blur hover':'grid-image-blur'} style={{"background-image":"url('../../../images/rural-electrification-scaled.jpg')"}}></div> */}
                </>
            }
              
              <Link to={'/'+this.props.data.slug}>
                <div className={this.state.blur? "opacity-div-grid active":"opacity-div-grid"} onMouseOver={this.ImageBlur} onMouseLeave={this.ImageBlurDeactivate} onClick={this.ImageBlurMobile}>
                    <GridPostCategories tags={this.props.data.tags}/>
                    <h1 className="post-title">{this.props.data.title}</h1>
                    <CatalogueMainText className={this.state.blur? "excerpt active":"excerpt"} style={{"color":"white","width":"100%","line-height":"120%","margin-bottom":"10px"}} dangerouslySetInnerHTML={{ __html: this.props.data.excerpt }}></CatalogueMainText>
                    
                    <Link to={'/'+this.props.data.slug} className="read-more w-inline-block">
                        <div className={this.state.readMore? "read-more-link active":"read-more-link"} onMouseOver={this.readMoreAnimate} onMouseOut={this.readMoreAnimate}>Read More</div>
                    </Link>
                    {/* <a href="#" className="read-more w-inline-block">
                    
                    </a> */}
                </div>
              </Link>
              
            </>
        );
    }
}

export default GridImage;