import React, { Component } from 'react';
import {Link} from "gatsby";
import "./css/progressBar.css"
import logo from "../../images/logo_svg.svg"
class ProgressBar extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className="progress-bar-wrapper">
                <Link to={"/"} className=""><img className="post-page-heading" src={logo} /></Link>
                <div className="progress-bar">
                    <div className="grey-bar"></div>
                    <div className={this.props.active? "yellow-bar":"yellow-bar full-width"} style={{"width":this.props.scroll}}></div>
                </div>
            </div>
        );
    }
}

export default ProgressBar;