import React from 'react';
import './css/gridPostCategories.css';
import {Link} from "gatsby";
const GridPostCategories = (props) => {
    return (       
        <div className="post-categories-wrapper">
                {props.tags.nodes.map((tag, i) => (                 
                    <Link to={'/category/'+tag.slug} className="post-category" key={i}>{tag.name}</Link>
                ))}
        </div>      
    );
};

export default GridPostCategories;