import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Category from '../views/Category/category'

export default function PostPage({data,pageContext}) {
const { monthName } = pageContext
  return (
    <Layout>
      <SEO title={"Category - "+monthName} />
      <Category data={data} categoryName={monthName}></Category>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String) {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}) {
        nodes {
          title
          content
          date
          slug
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
  }
`