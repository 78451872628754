import React, { Component } from 'react';
import "./css/divider.css"
class Divider extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            this.props.CategoryHeading!=""?
            <div className={this.props.className=='v-85'? "divider v-85":"divider"}>
                <h3 className="category-heading">
                    {this.props.CategoryHeading}
                </h3>
                <div className="category-heading-divider"></div>
            </div>
            :""
        );
    }
}

export default Divider;