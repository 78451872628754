import React, { Component } from 'react';
import styled,{css} from "styled-components";
import GridContentBlock from '../../elements/GridContentBlock/GridContentBlock'
const GridOneBlock = styled.div`
    display: -ms-grid;
    display: grid;
    height: 80vh;
    width:100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto;
    @media screen and (max-width: 991px) {
        grid-row-gap: 10px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    }
    @media screen and (max-width: 767px) {
        height: 1010px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto;
    }
    @media screen and (max-width: 479px) {
        height: 1920px;
        -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    }
`;
const G1 = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    -ms-grid-row: span 3;
    grid-row-start: span 3;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    @media screen and (max-width: 991px) {
        -ms-grid-row: span 5;
        grid-row-start: span 5;
        -ms-grid-row-span: 5;
        grid-row-end: span 5;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
    @media screen and (max-width: 767px) {
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
    @media screen and (max-width: 479px) {
        -ms-grid-row: span 3;
        grid-row-start: span 3;
        -ms-grid-row-span: 3;
        grid-row-end: span 3;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }
`;
const G2 = styled(G1)`
    -ms-grid-row: span 4;
    grid-row-start: span 4;
    -ms-grid-row-span: 4;
    grid-row-end: span 4;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
`;
const G3 = styled(G2)``;
const G4 = styled(G2)``;
const G5 = styled(G2)``;
const G6 = styled(G2)`
    -ms-grid-row: span 3;
    grid-row-start: span 3;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
`;
const GridOne = (props) => {
    return (
        <GridOneBlock>
               {props.data[0]?               
                    <G1>
                        <GridContentBlock data={props.data[0]} hoverState={false}></GridContentBlock>
                    </G1>
                    :""
                 }
                 {props.data[1]? 
                    <G2>
                        <GridContentBlock data={props.data[1]} hoverState={false}></GridContentBlock>
                    </G2>
                :""                
                 }
                {props.data[2]?
                    <G3>
                        <GridContentBlock data={props.data[2]} hoverState={false}></GridContentBlock>              
                    </G3>
                :""
                }
                {props.data[3]?
                     <G4>
                        <GridContentBlock data={props.data[3]} hoverState={false}></GridContentBlock>
                    </G4>
                :""
                }
                {props.data[4]?
                    <G5>
                        <GridContentBlock data={props.data[4]} hoverState={false}></GridContentBlock>
                    </G5>
                :""
                }
                {props.data[5]?
                    <G6>
                        <GridContentBlock data={props.data[5]} hoverState={false}></GridContentBlock>
                    </G6>
                :""
                }
        </GridOneBlock>
    );
};

export default GridOne;
